@media screen and (max-width: 1200px) {
    p {
        font-size: 1em;
    }

    h1 {
        font-size: 3.5em;
        padding: 0;
    }

    h2 {
        font-size: 2.8em;
    }

    h3 {
        font-size: 1.60em;
    }

    h4 {
        font-size: 1.3em;
    }

    .upperContentBox {
        max-width: 540px;
        padding: var(--space-l) var(--space-m) var(--space-m) var(--space-m);
    }

    .mainBox {
        flex-direction: column-reverse;
    }

    .socialBox {
        padding-bottom: var(--space-xxl);
    }

    .socialLogo {
        width: 50px;
        height: 50px;
    }

    .lowerSectionTop {
        padding: var(--space-l) 0 var(--space-m) 0; 
    }

    .lowerSectionMain {
        margin-top: -33px;
    }

    .lowerContentBox {
        width: fill-content;
        max-width: 540px;
        padding: 0 var(--space-m) var(--space-m) var(--space-m);
    }

    .projectIntroBox {
        margin: var(--space-m) auto var(--space-l) auto;
    }

    .projectIntroBox p {
        font-size: 1.1em;
    }

    .projectsBox {
        flex-direction: column;
        margin: 0 auto var(--space-l) auto;
        gap: var(--space-m);
    }

    .projectsBox img {
        width: 150px;
        height: 150px;
    }

    .creditsBox {
        margin: 0 var(--space-xs) var(--space-m) var(--space-xs);
    }
}
