@import url('https://fonts.googleapis.com/css?family=Nunito:300');
@import url('https://fonts.googleapis.com/css2?family=Vidaloka&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto:300');
@import url('https://fonts.googleapis.com/css?family=Material+Icons');

@import "theme.light.css" (prefers-color-scheme: light);
@import "theme.dark.css" (prefers-color-scheme: dark);
@import "tokens.css";
@import "mobile.css";
@import "desktop.css";

:root {

    /* Spaces */

    --space-xxs: 5px;
    --space-xs: 15px;
    --space-s: 23px;
    --space-m: 30px;
    --space-l: 60px;
    --space-xl: 90px;
    --space-xxl: 120px;
    --space-xxxl: 180px;


    /* Fonts */

    --font-heading: 'Vidaloka', serif;
    --font-title: 'roboto-regular', sans-serif;
    --font-body: 'roboto-regular', sans-serif;
    --font-link: 'roboto', sans-serif;
}


html, body {
    margin: 0;
    background: linear-gradient(var(--md-sys-color-primary), var(--md-sys-color-primary-gradient));
}

p {
    margin: 0;
    font-family: var(--font-body);
    color: var(--md-sys-color-on-background);
    line-height: 1.5em;
}


h1, h2, h3, h4 {
    margin: 0;
    width: fit-content;
    color: var(--md-sys-color-on-background);
}

h1 {
    line-height: 1;
    font-family: var(--font-heading);
    font-weight: normal;
}

h2 {
    font-family: var(--font-heading);
    font-weight: normal;
}

h3 {
    font-family: var(--font-title);
}

h4 {
    font-family: var(--font-body);
    color: var(--md-sys-color-on-background);
}

.rootBox {
    flex-direction: column;
    display: flex;
    height: 100vh;
}

.upperContentBox {
    margin: 0 auto 0 auto;
}

.mainBox {
    display: flex;
    justify-content: space-between;
}

.socialBox {
    justify-content: space-between;
    flex-direction: row;
    display: flex;
    align-items: center;
}

.socialLogo path {
    fill: var(--md-sys-color-on-primary);
}

.socialBox a:hover > .socialLogo path {
    fill: var(--md-sys-color-tertiary-container);
}

.titleBox h1 {
    color: var(--md-sys-color-on-primary);
    margin-bottom: var(--space-s);
}

.titleBox p {
    color: var(--md-sys-color-on-primary);
}

.titleBox h1 span {
    animation: blink linear 2s;
    animation-delay: 1s;
}

@keyframes blink {
    78% {
        color: inherit;
        text-shadow: inherit;
    }

    79% {
        /* TODO cannot use var(--color-primary) for some reason */
        color: #4F99AD;
    }

    80% {
        text-shadow: none;
    }

    81% {
        color: inherit;
        text-shadow: inherit;
    }

    82% {
        color: #4F99AD;
        text-shadow: none;
    }

    83% {
        color: inherit;
        text-shadow: inherit;
    }

    92% {
        color: #4F99AD;
        text-shadow: none;
    }

    92.5% {
        color: inherit;
        text-shadow: inherit;
    }
}

.mainBox {
    margin-bottom: var(--space-xxl);
}

.scrollIndicator {
    animation: bounce 2s;
    font-family: 'Material Icons', serif;
    font-weight: normal;
    font-style: normal;
    font-size: 64px;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    text-align: center;
    color: var(--md-sys-color-on-primary);

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-30px);
    }

    60% {
        transform: translateY(-15px);
    }
}

.lowerSectionTop {
    transform: skew(0deg, 3deg);
    position: relative;
    background-color: var(--md-sys-color-background);
}

.lowerSectionMain {
    flex: 1;
    z-index: 1;
    background-color: var(--md-sys-color-background);
}

.lowerContentBox {
    margin: 0 auto 0 auto;
}

.projectIntroBox h2 {
    margin: 0 auto var(--space-xxs) auto;
}

.projectIntroBox p {
    text-align: center;
    margin: 0 auto 0 auto;
    font-style: italic;
    width: fit-content;
}

.projectsBox {
    display: flex;
    justify-content: space-between;
}

.projectsBox a:link, .projectsBox a:visited {
    flex: 1;
    border-radius: var(--space-xs);
    box-shadow: rgba(60, 64, 67, 0.3) 0 1px 2px 0, rgba(60, 64, 67, 0.15) 0 2px 6px 2px;
    background-color: var(--md-sys-color-surface-container-low);
    text-decoration: none;
    padding: var(--space-m);
}

.projectsBox a:hover {
    background-color: var(--md-sys-color-surface-container);
}

.projectsBox img {
    margin: 0 auto var(--space-m) auto;
    display: block;
}

.projectsBox h3 {
    margin-bottom: var(--space-xxs);
}


.creditsBox {
    text-align: center;
}

.creditsBox p {
    font-size: 0.8em;
}

.creditsBox a:link, .creditsBox a:visited {
    color: var(--md-sys-color-on-background);
}

.creditsBox a:hover {
    color: var(--md-sys-color-secondary);
}
