@media screen and (min-width: 1200px) {
    body {
        padding-top: 180px;
    }

    p {
        font-size: 1.25em;
    }

    h1 {
        font-size: 4.75em;
    }

    h2 {
        font-size: 3.55em;
    }

    h3 {
        font-size: 1.9em;
    }

    h4 {
        font-size: 1.2em;
    }

    .upperContentBox {
        width: 1080px;
        padding: 0 var(--space-l) 0 var(--space-l);
    }

    .mainBox {
        flex-direction: row;
    }

    .titleBox {
        width: 400px;
        margin: auto 0 auto 0;
    }

    .socialBox {
        width: 450px;
        margin: auto 0 auto 0;
    }

    .socialLogo {
        width: 70px;
        height: 70px;
    }

    .scrollIndicator {
        margin-bottom: var(--space-xxl);
    }

    .lowerSectionTop {
        padding: var(--space-xxl) 0 var(--space-l) 0;
    }

    .lowerSectionMain {
        margin-top: -95px;
    }

    .lowerContentBox {
        width: 1080px;
        padding: 0 var(--space-l) 0 var(--space-l);
    }

    .projectIntroBox {
        margin: 0 auto var(--space-l) auto;
    }

    .projectIntroBox p {
        font-size: 1.2em;
    }

    .projectsBox {
        flex-direction: row;
        margin: 0 auto var(--space-xxl) auto;
        gap: var(--space-xxl);
    }

    .creditsBox {
        width: fit-content;
        margin: 0 auto var(--space-m) auto;
    }
}
