:root {
  --md-source: #4F99AD;
  /* primary */
  --md-ref-palette-primary0: #000000;
  --md-ref-palette-primary5: #001419;
  --md-ref-palette-primary10: #001F26;
  --md-ref-palette-primary20: #003641;
  --md-ref-palette-primary25: #00424F;
  --md-ref-palette-primary30: #004E5D;
  --md-ref-palette-primary35: #005B6C;
  --md-ref-palette-primary40: #00687B;
  --md-ref-palette-primary50: #00829A;
  --md-ref-palette-primary60: #009EBB;
  --md-ref-palette-primary70: #2FBAD9;
  --md-ref-palette-primary80: #57D6F6;
  --md-ref-palette-primary90: #AEECFF;
  --md-ref-palette-primary95: #D9F5FF;
  --md-ref-palette-primary98: #F1FBFF;
  --md-ref-palette-primary99: #F8FDFF;
  --md-ref-palette-primary100: #FFFFFF;
  /* secondary */
  --md-ref-palette-secondary0: #000000;
  --md-ref-palette-secondary5: #001419;
  --md-ref-palette-secondary10: #061F25;
  --md-ref-palette-secondary20: #1D343A;
  --md-ref-palette-secondary25: #283F45;
  --md-ref-palette-secondary30: #344A51;
  --md-ref-palette-secondary35: #3F565D;
  --md-ref-palette-secondary40: #4B6269;
  --md-ref-palette-secondary50: #647B82;
  --md-ref-palette-secondary60: #7D959C;
  --md-ref-palette-secondary70: #97AFB7;
  --md-ref-palette-secondary80: #B2CBD3;
  --md-ref-palette-secondary90: #CEE7EF;
  --md-ref-palette-secondary95: #DCF5FD;
  --md-ref-palette-secondary98: #F1FBFF;
  --md-ref-palette-secondary99: #F8FDFF;
  --md-ref-palette-secondary100: #FFFFFF;
  /* tertiary */
  --md-ref-palette-tertiary0: #000000;
  --md-ref-palette-tertiary5: #090E2C;
  --md-ref-palette-tertiary10: #141937;
  --md-ref-palette-tertiary20: #292E4D;
  --md-ref-palette-tertiary25: #343959;
  --md-ref-palette-tertiary30: #404565;
  --md-ref-palette-tertiary35: #4B5071;
  --md-ref-palette-tertiary40: #575C7E;
  --md-ref-palette-tertiary50: #707598;
  --md-ref-palette-tertiary60: #8A8FB3;
  --md-ref-palette-tertiary70: #A4A9CE;
  --md-ref-palette-tertiary80: #C0C4EB;
  --md-ref-palette-tertiary90: #DEE0FF;
  --md-ref-palette-tertiary95: #F0EFFF;
  --md-ref-palette-tertiary98: #FBF8FF;
  --md-ref-palette-tertiary99: #FEFBFF;
  --md-ref-palette-tertiary100: #FFFFFF;
  /* error */
  --md-ref-palette-error0: #000000;
  --md-ref-palette-error5: #2D0001;
  --md-ref-palette-error10: #410002;
  --md-ref-palette-error20: #690005;
  --md-ref-palette-error25: #7E0007;
  --md-ref-palette-error30: #93000A;
  --md-ref-palette-error35: #A80710;
  --md-ref-palette-error40: #BA1A1A;
  --md-ref-palette-error50: #DE3730;
  --md-ref-palette-error60: #FF5449;
  --md-ref-palette-error70: #FF897D;
  --md-ref-palette-error80: #FFB4AB;
  --md-ref-palette-error90: #FFDAD6;
  --md-ref-palette-error95: #FFEDEA;
  --md-ref-palette-error98: #FFF8F7;
  --md-ref-palette-error99: #FFFBFF;
  --md-ref-palette-error100: #FFFFFF;
  /* neutral */
  --md-ref-palette-neutral0: #000000;
  --md-ref-palette-neutral5: #0E1113;
  --md-ref-palette-neutral10: #191C1D;
  --md-ref-palette-neutral20: #2E3132;
  --md-ref-palette-neutral25: #393C3D;
  --md-ref-palette-neutral30: #444748;
  --md-ref-palette-neutral35: #505354;
  --md-ref-palette-neutral40: #5C5F60;
  --md-ref-palette-neutral50: #757779;
  --md-ref-palette-neutral60: #8E9192;
  --md-ref-palette-neutral70: #A9ABAD;
  --md-ref-palette-neutral80: #C5C7C8;
  --md-ref-palette-neutral90: #E1E3E4;
  --md-ref-palette-neutral95: #EFF1F2;
  --md-ref-palette-neutral98: #F8F9FB;
  --md-ref-palette-neutral99: #FBFCFE;
  --md-ref-palette-neutral100: #FFFFFF;
  /* neutral-variant */
  --md-ref-palette-neutral-variant0: #000000;
  --md-ref-palette-neutral-variant5: #0A1215;
  --md-ref-palette-neutral-variant10: #141D20;
  --md-ref-palette-neutral-variant20: #293235;
  --md-ref-palette-neutral-variant25: #343D40;
  --md-ref-palette-neutral-variant30: #3F484B;
  --md-ref-palette-neutral-variant35: #4B5457;
  --md-ref-palette-neutral-variant40: #576063;
  --md-ref-palette-neutral-variant50: #70787C;
  --md-ref-palette-neutral-variant60: #899295;
  --md-ref-palette-neutral-variant70: #A4ADB0;
  --md-ref-palette-neutral-variant80: #BFC8CB;
  --md-ref-palette-neutral-variant90: #DBE4E7;
  --md-ref-palette-neutral-variant95: #E9F2F6;
  --md-ref-palette-neutral-variant98: #F2FBFE;
  --md-ref-palette-neutral-variant99: #F8FDFF;
  --md-ref-palette-neutral-variant100: #FFFFFF;
  /* light */
  --md-sys-color-primary-light: #00687B;
  --md-sys-color-on-primary-light: #FFFFFF;
  --md-sys-color-primary-container-light: #AEECFF;
  --md-sys-color-on-primary-container-light: #001F26;
  --md-sys-color-primary-fixed-light: #AEECFF;
  --md-sys-color-on-primary-fixed-light: #001F26;
  --md-sys-color-primary-fixed-dim-light: #57D6F6;
  --md-sys-color-on-primary-fixed-variant-light: #004E5D;
  --md-sys-color-secondary-light: #4B6269;
  --md-sys-color-on-secondary-light: #FFFFFF;
  --md-sys-color-secondary-container-light: #CEE7EF;
  --md-sys-color-on-secondary-container-light: #061F25;
  --md-sys-color-secondary-fixed-light: #CEE7EF;
  --md-sys-color-on-secondary-fixed-light: #061F25;
  --md-sys-color-secondary-fixed-dim-light: #B2CBD3;
  --md-sys-color-on-secondary-fixed-variant-light: #344A51;
  --md-sys-color-tertiary-light: #575C7E;
  --md-sys-color-on-tertiary-light: #FFFFFF;
  --md-sys-color-tertiary-container-light: #DEE0FF;
  --md-sys-color-on-tertiary-container-light: #141937;
  --md-sys-color-tertiary-fixed-light: #DEE0FF;
  --md-sys-color-on-tertiary-fixed-light: #141937;
  --md-sys-color-tertiary-fixed-dim-light: #C0C4EB;
  --md-sys-color-on-tertiary-fixed-variant-light: #404565;
  --md-sys-color-error-light: #BA1A1A;
  --md-sys-color-on-error-light: #FFFFFF;
  --md-sys-color-error-container-light: #FFDAD6;
  --md-sys-color-on-error-container-light: #410002;
  --md-sys-color-outline-light: #70787C;
  --md-sys-color-background-light: #FBFCFE;
  --md-sys-color-on-background-light: #191C1D;
  --md-sys-color-surface-light: #F8F9FB;
  --md-sys-color-on-surface-light: #191C1D;
  --md-sys-color-surface-variant-light: #DBE4E7;
  --md-sys-color-on-surface-variant-light: #3F484B;
  --md-sys-color-inverse-surface-light: #2E3132;
  --md-sys-color-inverse-on-surface-light: #EFF1F2;
  --md-sys-color-inverse-primary-light: #57D6F6;
  --md-sys-color-shadow-light: #000000;
  --md-sys-color-surface-tint-light: #00687B;
  --md-sys-color-outline-variant-light: #BFC8CB;
  --md-sys-color-scrim-light: #000000;
  --md-sys-color-surface-container-highest-light: #E1E3E4;
  --md-sys-color-surface-container-high-light: #E6E8E9;
  --md-sys-color-surface-container-light: #ECEEEF;
  --md-sys-color-surface-container-low-light: #F2F4F5;
  --md-sys-color-surface-container-lowest-light: #FFFFFF;
  --md-sys-color-surface-bright-light: #F8F9FB;
  --md-sys-color-surface-dim-light: #D8DADB;
  /* dark */
  --md-sys-color-primary-dark: #57D6F6;
  --md-sys-color-on-primary-dark: #003641;
  --md-sys-color-primary-container-dark: #004E5D;
  --md-sys-color-on-primary-container-dark: #AEECFF;
  --md-sys-color-primary-fixed-dark: #AEECFF;
  --md-sys-color-on-primary-fixed-dark: #001F26;
  --md-sys-color-primary-fixed-dim-dark: #57D6F6;
  --md-sys-color-on-primary-fixed-variant-dark: #004E5D;
  --md-sys-color-secondary-dark: #B2CBD3;
  --md-sys-color-on-secondary-dark: #1D343A;
  --md-sys-color-secondary-container-dark: #344A51;
  --md-sys-color-on-secondary-container-dark: #CEE7EF;
  --md-sys-color-secondary-fixed-dark: #CEE7EF;
  --md-sys-color-on-secondary-fixed-dark: #061F25;
  --md-sys-color-secondary-fixed-dim-dark: #B2CBD3;
  --md-sys-color-on-secondary-fixed-variant-dark: #344A51;
  --md-sys-color-tertiary-dark: #C0C4EB;
  --md-sys-color-on-tertiary-dark: #292E4D;
  --md-sys-color-tertiary-container-dark: #404565;
  --md-sys-color-on-tertiary-container-dark: #DEE0FF;
  --md-sys-color-tertiary-fixed-dark: #DEE0FF;
  --md-sys-color-on-tertiary-fixed-dark: #141937;
  --md-sys-color-tertiary-fixed-dim-dark: #C0C4EB;
  --md-sys-color-on-tertiary-fixed-variant-dark: #404565;
  --md-sys-color-error-dark: #FFB4AB;
  --md-sys-color-on-error-dark: #690005;
  --md-sys-color-error-container-dark: #93000A;
  --md-sys-color-on-error-container-dark: #FFDAD6;
  --md-sys-color-outline-dark: #899295;
  --md-sys-color-background-dark: #191C1D;
  --md-sys-color-on-background-dark: #E1E3E4;
  --md-sys-color-surface-dark: #111415;
  --md-sys-color-on-surface-dark: #C5C7C8;
  --md-sys-color-surface-variant-dark: #3F484B;
  --md-sys-color-on-surface-variant-dark: #BFC8CB;
  --md-sys-color-inverse-surface-dark: #E1E3E4;
  --md-sys-color-inverse-on-surface-dark: #191C1D;
  --md-sys-color-inverse-primary-dark: #00687B;
  --md-sys-color-shadow-dark: #000000;
  --md-sys-color-surface-tint-dark: #57D6F6;
  --md-sys-color-outline-variant-dark: #3F484B;
  --md-sys-color-scrim-dark: #000000;
  --md-sys-color-surface-container-highest-dark: #323536;
  --md-sys-color-surface-container-high-dark: #272A2C;
  --md-sys-color-surface-container-dark: #1D2021;
  --md-sys-color-surface-container-low-dark: #191C1D;
  --md-sys-color-surface-container-lowest-dark: #0B0F10;
  --md-sys-color-surface-bright-dark: #363A3B;
  --md-sys-color-surface-dim-dark: #111415;
}
